import React from 'react';
import './Dinner.css';

class DinnerOption extends React.Component {
    render() {
        return (
            <div>
                <p className="IntroText">You should have</p>
                <p className="DinnerText">{this.props.dinner}</p>
            </div>
        );
    }
}

export default DinnerOption;