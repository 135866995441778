import React from 'react';
import './Dinner.css';
import DinnerOption from './DinnerOption'
import dinnerOptions from './dinners.json'

class Dinner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dinner: ""
        };
    }

    componentDidMount() {
        this.selectDinner();
    }

    selectDinner() {
        var index = Math.round(Math.random() * (dinnerOptions.length - 1));
        var selectedOption = dinnerOptions[index];
        this.setState(state => ({
            dinner: selectedOption
        }));
    }

    render() {
        return (
            <div>
                <div className="Main">
                    <div>
                        <DinnerOption dinner={this.state.dinner.name}></DinnerOption>
                    </div>
                </div>
                <div className="NextButtonContainer">
                    <button className="NextButton" onClick={this.selectDinner.bind(this)}>I Want Something Else</button>
                </div>
            </div>
        );
    }
}

export default Dinner;