import React from 'react';
import './Header.css';
import ProfileButton from '../ProfileButton'

class Header extends React.Component {
  constructor(props) {
    super(props);

    console.log(this.props.showProfile)
  }

  showProfile() {
    if(!this.props.showProfile) {
      return
    } else {
      return (<ProfileButton/>)
    }
  }

  render() {
    return (
      <header className="App-header">
        <div className="Header-container-left">
          <p className="App-title">Welcome to Dinner Selector</p>
        </div>
          {this.showProfile()}
      </header>
    );
  }
}

export default Header;