import React from 'react';
import firebase from "firebase/app";
import Header from '../../Components/Header/Header';
import "firebase/auth";
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer
} from "@react-firebase/auth";

class UserAuthentication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          signIn: true
       };
    }

    signUp() {
        if(this.passwordInput.value === this.verifyPasswordInput.value) {
            firebase.app().auth().createUserWithEmailAndPassword(this.emailInput.value, this.passwordInput.value).then((userCredential) => {
                window.location.href = "/"
            }).catch((error) => {
                
            });;
        }
    }

    signIn() {
        firebase.app().auth().signInWithEmailAndPassword(this.emailInput.value, this.passwordInput.value).then((userCredential) => {
            window.location.href = "/"
        }).catch((error) => {

        });
    }

    signOut() {
        firebase.app().auth().signOut();
    }

    showSignIn() {
        this.setState(state => ({
            signIn: true
        }));
    }

    showSignUp() {
        this.setState(state => ({
            signIn: false
        }));
    }

    render() {
        return (
            <div>
                <Header showProfile={false}/>
                <FirebaseAuthProvider firebase={firebase} {...{}}>
                    <FirebaseAuthConsumer>
                        {({ isSignedIn, firebase }) => {
                            if (isSignedIn === true) {
                                return (
                                    <div>
                                    <h2>You're signed in 🎉 </h2>
                                    <button onClick={this.signOut.bind(this)}>Sign out</button>
                                    </div>
                                );
                            } else {
                                if (this.state.signIn) {
                                    return (
                                        <div>
                                        <h2>Sign In</h2>
                                        <input id="email" placeholder="email" ref={el => this.emailInput = el}></input>
                                        <input id="password" placeholder="password" type="password" ref={el => this.passwordInput = el}></input>
                                        <button onClick={this.signIn.bind(this)}>Sign in</button>
                                        <button onClick={this.showSignUp.bind(this)}>Sign up</button>
                                        </div>
                                    );
                                } else {
                                    return(
                                    <div>
                                        <h2>Sign Up</h2>
                                        <input id="email" placeholder="email" ref={el => this.emailInput = el}></input>
                                        <input id="password" placeholder="password" type="password" ref={el => this.passwordInput = el}></input>
                                        <input id="verify-password" placeholder="verify password" type="password" ref={el => this.verifyPasswordInput = el}></input>
                                        <button onClick={this.signUp.bind(this)}>Sign up</button>
                                        <button onClick={this.showSignIn.bind(this)}>Already have an account? Sign in</button>
                                    </div>
                                    );
                                }
                            }
                        }}
                    </FirebaseAuthConsumer>
                </FirebaseAuthProvider>
            </div>
        )
    }
}

export default UserAuthentication;