import React from 'react';
import './Home.css';
import firebase from "firebase/app";
import Header from '../../Components/Header/Header';
import Dinner from '../Dinner/Dinner';
import UserDinner from '../Dinner/UserDinner';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dinner: "",
      signedIn: firebase.auth().currentUser != null
   };

   
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState(state => ({
          signedIn: user != null
      }));
    });
}

  render() {
    if(this.state.signedIn) {
      return (
        <div className="App">
          <Header showProfile/>
          <UserDinner></UserDinner>
        </div>
      );
    } else {
      return (
        <div className="App">
          <Header showProfile/>
          <Dinner></Dinner>
        </div>
      );
    }
  }
}

export default Home;