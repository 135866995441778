import React from 'react';
import './YourDinners.css';
import firebase from "firebase/app";
import "firebase/firebase-database"
import Header from '../../Components/Header/Header';
import {Link} from 'react-router-dom';

class YourDinners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dinnerOptions: []
        }
    }

    componentDidMount() {
        if(firebase.auth().currentUser !== null) {
            console.log(firebase.auth().currentUser.uid)
            var dinnerOptionsRef = firebase.database().ref('dinners/' + firebase.auth().currentUser.uid);
            if(dinnerOptionsRef !== null) {
                dinnerOptionsRef.on('value', (snapshot) => {
                    if(snapshot.val() !== null) {
                        console.log(snapshot.val())
                        var json = snapshot.toJSON();
                        var keys = Object.keys(json);
                        var items = []

                        for (let index in keys) {
                            let item = {
                                "key": keys[index],
                                "dinner": json[keys[index]]
                            }
                            items.push(item)
                        }

                        console.log(items)

                        this.setState(state => ({
                            dinnerOptions: items
                        }));
                    }
                }).bind(this)
            }
        }
    }

    renderDinners() {
        var items = []

        for(let value in this.state.dinnerOptions) {
            console.log(this.state.dinnerOptions[value].dinner)
            console.log(this.state.dinnerOptions[value].key)
            let dinner = this.state.dinnerOptions[value].dinner
            let key = this.state.dinnerOptions[value].key

            items.push(
            <div key={key}>
                <p>{dinner}</p>
                <button onClick={() => {this.removeItem(key)}}>Remove</button>
            </div>
            )
        }

        return items
    }

    addDinnerOption() {
        firebase.database().ref('dinners/' + firebase.auth().currentUser.uid).push().set(
            this.dinnerInput.value
        );
    }

    removeItem(key) {
        firebase.database().ref('dinners/' + firebase.auth().currentUser.uid + '/' + key).remove()
    }

    render() {
        return (
        <div className="App">
            <Header />
            <div>
                <Link to="/">Select Dinner</Link>
            </div>
            <div>
                <input id="dinnerInput" placeholder="dinner" ref={el => this.dinnerInput = el}></input>
                <button onClick={this.addDinnerOption.bind(this)}>Add New Dinner</button>
            </div>
            <div class="DinnersContainer">
                {this.renderDinners()}
            </div>
        </div>
        );
    }
}

export default YourDinners;