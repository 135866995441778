import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './Pages/Home/Home';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import UserAuthentication from './Pages/Authentication/UserAuthentication';
import YourDinners from './Pages/YourDinners/YourDinners';
import firebase from "firebase/app";
import "firebase/auth";

if(firebase.apps.length === 0) {
  var config = {
      apiKey: "AIzaSyBZpNa3D7hxuKaQyHy7o84Kjv46rJRpWfk",
      authDomain: "dinnerselector.firebaseapp.com",
      projectId: "dinnerselector",
      storageBucket: "dinnerselector.appspot.com",
      messagingSenderId: "494878529554",
      appId: "1:494878529554:web:08157c8bc702dee066a0cb",
      measurementId: "G-JJPH74SECT"
  };

  firebase.initializeApp(config);
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/login">
          <UserAuthentication />
        </Route>
        <Route path="/dinners">
          <YourDinners />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
