import React from 'react';
import './Dinner.css';
import DinnerOption from './DinnerOption'
import dinnerOptions from './dinners.json'
import firebase from "firebase/app";
import {} from "firebase/firebase-database"
import {Link} from 'react-router-dom';

class UserDinner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dinner: ""
        };

        console.log(firebase.auth().currentUser.uid)
        this.userOptions = []
        this.selectedOptions = []
    }

    componentDidMount() {
        var dinnerOptionsRef = firebase.database().ref('dinners/' + firebase.auth().currentUser.uid);
        dinnerOptionsRef.on('value', (snapshot) => {
            if(snapshot.toJSON() !== null) {
                this.userOptions = Object.values(snapshot.toJSON());
                if(this.state.dinner === "") {
                    this.selectDinner();
                }
            }
        }).bind(this)
    }

    addDinnerOption(dinner) {
        firebase.database().ref('dinners/' + firebase.auth().currentUser.uid).push().set(
            dinner
        );
    }

    availableOptions() {
        if(this.userOptions.length === this.selectedOptions.length) {
            this.selectedOptions = [];
        }

        var options = this.userOptions.filter((value, index, array) => {
            if(this.selectedOptions.indexOf(value) !== -1) {
                return false;
            } else {
                return true;
            }
        });

        return options;
    }

    selectDinner() {
        var options = this.availableOptions();
        var index = Math.round(Math.random() * (options.length - 1));
        var selectedOption = options[index];
        this.selectedOptions.push(selectedOption);
        this.setState(state => ({
            dinner: selectedOption
        }));
    }
    
    selectRandomDinner() {
        var index = Math.round(Math.random() * (dinnerOptions.length - 1));
        var selectedOption = dinnerOptions[index];
        console.log(selectedOption)
        this.setState(state => ({
            dinner: selectedOption.name
        }));
    }

    render() {
        return (
            <div>
                <div className="Main">
                    <Link to="/dinners">Edit My Dinner Options</Link>
                    <div>
                        <DinnerOption dinner={this.state.dinner}></DinnerOption>
                    </div>
                </div>
                <div className="NextButtonContainer">
                    <button className="NextButton" onClick={this.selectDinner.bind(this)}>I Want Something Else</button>
                    <button className="NextButton" onClick={this.selectRandomDinner.bind(this)}>Give Me Something Random</button>
                </div>
            </div>
        );
    }
}

export default UserDinner;