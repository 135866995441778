import React from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import {Link} from 'react-router-dom';

class ProfileButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: firebase.auth().currentUser
        };
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            this.setState(state => ({
                user: user
            }));
        });
    }

    signOut() {
        firebase.app().auth().signOut();
        window.location.href = "/"
    }

    loginOrProfile() {
        if(this.state.user != null) {
            return (
                <div className="LoggedInContainer">
                    <p>{this.state.user.email}</p>
                    <button onClick={this.signOut.bind(this)}>Sign Out</button>
                </div>
            )
        } else {
            return (
                <Link to="/login">Log In</Link>
            )
        }
    }

    render() {
        return (
            <div className="ProfileContainer">
                {this.loginOrProfile()}
            </div>
        )
    }
}

export default ProfileButton;